nav{
    align-self: center;
    display: flex;
}
.navWrapperItem{
    margin-inline: 10px;
    list-style: none;
    display: inline;
}

.navItem{
    padding: 45px 0;
    color: #202F44;
    transition: all 0.4s;
    text-decoration: none;
}
.navItem:hover{
    color: #0045FF;
    cursor: pointer;
}

@media (max-width: 450px) {
    nav{
        display: block;
    }
}