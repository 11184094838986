.body{
  margin-inline: 10px;
}
.services{
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
}
.title{
  text-align: center;
}

.aboutWrap{
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 630px) {
  .services{
    grid-template-columns: 1fr;
  }
}