.header{
    height: 100px;
    display: inline-grid;
    background-color: #EBEFFF;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
}
.header div{
    align-self: center;
    margin: 10px;
}

.header-title{
    margin: auto;
}

.header-phone-div{
    text-align: end;
}

.header-phone{
    text-align: end;
    font-size: 22px;
    text-decoration: none;
}

@media (max-width: 1078px) {
    .header{
        grid-template-columns: 1fr 1fr;
        height: 200px;
    }    
    .header-logo{
        grid-row: 1;
    }
    .header-phone-div{
        grid-row: 1;
    }
    .header-menu{
        grid-row: 2;
        grid-column: 1/3;    
    }
}

@media (max-width: 560px) {
    .header-phone{
        font-size: 15px;
    }
    .header-logo img{
        width: 200px;
    }
}